import HeadPic from "./headpic";

const profile = {
  position: "Creative Designer / Developer",
  location: "Madrid",
  bio: [
    "Along my journey, I had the opportunity to explore both design and tech. From that experience, I built a critical point of view which made me aware of the boundaries and possibilities design from project management to user acceptance.",
    "Here you’ll find a selection of projects coming from my initiative or responding to the needs of professionals and artists. I wanted to represent the diversity of project on which I worked on, and the spectrum of skills I developed while building them. This website itself aims to represent my skills: designed and developed by myself.",
    "Note: check out more UI project I did on my Behance profile below.",
  ],
  links: {
    linkedin: "https://www.linkedin.com/in/giardinav/",
    behance: "https://www.behance.net/giardi",
    insta: "https://www.instagram.com/giardiii/",
    twitter: "https://twitter.com/giardiv",
  },
};

const Jumbo = (props) => {
  return (
    <div className="jumbo">
      <div className="gradient"></div>
      <div className="side">
        <h1>
          Vincent Giardina
          <div>
            <b>NYC</b> 
            {/* • relocating to  soon */}
          </div>
        </h1>
        <h2>Product Designer – Creative Developer</h2>
        <div className="description">
          <p>
            Along my journey, I have had the opportunity to explore both{" "}
            <b>design and engineering</b>. From that experience, I have built a
            critical point of view which has made me aware of the potential of
            various mediums and technics. Now, I enjoy working on early stage
            projects, where I'm able to participate in building foundations.
          </p>
          <p>
            My skills are focused on <b>Prototyping</b>, <b>UI design</b> and{" "}
            <b>Implementation</b>. I have experiences in user research,
            interviews and UX in general, but the best part is working on the{" "}
            <b>construction of the final product</b>.
          </p>
          <p>
            Here you’ll find a selection of projects responding to the needs of
            professionals and artists. As well, some of them are from my own
            initiative. I wanted to represent the diversity of projects on which
            I have worked on, and the spectrum of skills I have developed while
            building them. This website itself aims to represent my skills:
            designed and developed by myself.
          </p>
        </div>
        <div className="footer">
          <div className="head">
            <HeadPic />
          </div>
          <div className="links">
            <a href={profile.links.linkedin} target="_blank">
              LinkedIn
            </a>
            <a href={profile.links.twitter} target="_blank">
              Twitter
            </a>
            <a href={profile.links.insta} target="_blank">
              Instagram
            </a>
            <a href={profile.links.behance} target="_blank">
              Behance
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jumbo;
