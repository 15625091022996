import React, { useState } from "react";

const Hey = () => {
  return (
    <div className="hey">
      {/* <div
        class="gatsby-image-wrapper"
        style={{
          position: "relative",
          overflow: "hidden",
          display: "inline-block",
          width: "30px",
          height: "30px",
        }}
      >
        <img
          src="img/fixed/hand.png"
          alt=""
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center center",
          }}
        />
      </div> */}
      {/* <a href="mailto:giardiv@yahoo.com">giardiv@yahoo.com</a> */}
    </div>
  );
};

export default Hey;
