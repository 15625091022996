import React, { useState } from "react"


const HeadPic = () => {
    const pic = {}

    return(
        <img src="img/fixed/face.png" style={{width: "80px"}}/>
    )
}

export default HeadPic