import content from './various.md'

export default {
    name: "Tiny UI + Code ",
    slug: "various",
    description: "This section collects smaller projects. On each of them, I’ve been both designing and coding the UI. The are all built with Figma and then implemented in React/Next.js",
    tags: ["UI design", "Front-end (React)", "Back-end (Node.js)"],
    color: "#8A6DDC",
    sub_color: "#A18AE3",
    with: "",
    for: "Clients or fun",
    //youtube: "RakJu0ELd3M",
    content: content
}

