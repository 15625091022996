import content from './nostalgia.md'

export default {
    name: "Digital Twin",
    slug: "nostalgia",
    description: "How to design for experiencing nostalgia? That's the exploratory question on which I've worked for this project. Through a extensive user research process, I tried to explore this feeling as a positive experience. From interviews and design probes, I discovered how personal and deep the feeling of nostalgia can be. I wanted to experiment with a lot of freedom. The final result is a design fiction video where AI becomes the nostalgic trigger through a phone call: creepy, funny, or utopist. Your choice.",
    tags: ["Design Fiction", "Experience Design", "Design Probes"],
    color: "#F09536",
    sub_color: "#F3AA5E",
    with: "",
    for: "Experience Design Course",
    youtube: "RakJu0ELd3M",
    content: content
}

