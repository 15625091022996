import projects from "../content/project";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";

export const Project = (props) => {
  const project = projects.find((project) => project.slug === props.project);
  const nextIndex = projects.findIndex(
    (project) => project.slug === props.project
  );
  const nextProject = projects[nextIndex + 1] || projects[0];
  const { name, description, slug, color, tags } = project;
  const open = props.open;

  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(project.content)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
      });
  }, [project]);

  return (
    <div
      className="project-page"
      style={{ borderColor: project.sub_color, top: open ? "64px" : "120vh" }}
    >
      <div
        className="close"
        style={{ borderColor: color }}
        onClick={props.close}
      >
        ✕
      </div>
      <div className="box" ref={props.boxRef}>
        <div className="container">
          <h1 className="project-title">
            {name}
            <span style={{ color: color }}>{name}</span>
          </h1>
          <header>
            <div className="left">
              <img
                className="project-img"
                src={"/img/" + slug + "/cover.png"}
                alt={name}
              />
            </div>
            <div className="right">
              <p className="project-description">{description}</p>
            </div>
          </header>
          <div className="infos">
            <div className="project-tags">
              {tags.map((tag) => {
                return (
                  <div
                    className="info"
                    style={{ borderColor: color, color: color }}
                  >
                    {tag}
                  </div>
                );
              })}
            </div>
            {project.with && (
              <div className="info">
                <b>With {project.with}</b>
              </div>
            )}
            {project.for && (
              <div className="info">
                <b>For {project.for}</b>
              </div>
            )}
          </div>

          {project.youtube && (
            <div class="video-container">
              <iframe
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/" + project.youtube}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          )}
          <ReactMarkdown className="content">{content}</ReactMarkdown>
          <div className="footer" style={{ borderColor: nextProject.color }}>
            <div onClick={() => props.openProjectFn(nextProject.slug)}>
              <div className="next-label">Next Project</div>
              <h1 style={{ color: nextProject.color }}>
                {nextProject.name}
                <img src="img/fixed/arrow.png" />
              </h1>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
