import content from "./nomic.md";

export default {
  name: "Nomic",
  slug: "nomic",
  description:
    "Nomic develops data visualizations for AI, I joined as the first designer. When I landed the product at a prototype stage, I worked on bringing the UI to an enterprise-grade level. I’ve started with creating a simple branding, to then derive a first design system. From this point, the product had a foundation to grow on. I kept on iterating, on both design and implementation on every side of the user-facing application. ",
  tags: ["UX/UI Design", "User Research","Dev (Next)"],
  color: "#A2BC39",
  sub_color: "#A0C01E",
  for: "work",
  content: content,
};
