import content from './burb.md'

export default {
    name: "Biennale Urbana",
    slug: "burb",
    description: "Biennale Urbana is an initiative composed of artists and architects from the Italian collective Stalker. During a workshop at the Instituto Svizzero of Rome, I designed the premises that will become the platform Biennale Urbana. The collective was looking for a way to document and share their journey. Many constraints were applied there: the importance of geographical positions, efficient and on-the-go usage, and high accessibility. Note that this project had artistic experimental purposes.",
    tags: ["Ix design", "UX design", "full-stack dev", "telgram bot"],
    color: "#FF5852",
    sub_color: "#FF7975",
    with: "Victor Nebbiolo di Castri (PASE Platform / Corte supernova) for the coordination",
    for: "Stalker: Biennale Urbana (Lorenzo Romito, Andrea Curtoni, Giuilla Arcadia)",
    content: content
}