import burb from "./projects/burb";
import datajumbo from "./projects/datajumbo";
import emotifont from "./projects/emotifont";
import family_photo from "./projects/family_photo";
import hopsworks from "./projects/hopsworks";
import nomic from "./projects/nomic";
import nostalgia from "./projects/nostalgia";
import various from "./projects/various";

export default [
    nomic,
    hopsworks,
    datajumbo,
    burb,
    family_photo,
    nostalgia,
    various,
    emotifont,
]