import content from './datajumbo.md'

export default {
    name: "Data Jumbo",
    slug: "dj",
    description: "Data Jumbo is a company I’ve built as indie hacker. I made available the chart creation to Notion users. I’ve been working on this project for over a year developing the product, exchanging with users, implementing features and marketing the project. Early 2023 I sold the company and I’m not anymore involved with it. It's been the most rewarding experience I've had so far.",
    tags: ["UX/UI Design", "Dev (React/Node)", "Marketing"],
    color: "#3152EA",
    sub_color: "#5A75EE",
    //with: "Victor Nebbiolo di Castri (PASE Platform / Corte supernova) for the coordination",
    for: "business experiment",
    content: content
}