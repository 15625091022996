import content from './emotifont.md'

export default {
    name: "Emotifont",
    slug: "emotifont",
    description: "I'm part of the generation which has texted emotions using few-character emoticons during its teenage years :) These character-combos became less used since these new generations of colored emojis appeared either on screen or even paper 😒. Back in the day, our homemade emojis would leave us some room for experimentation where only the limitation of our keyboard (ノಠ益ಠ)ノ Nostalgic, I wanted to bring back to life that creative typographic by designing an emoji-friendly font.",
    tags: ["Typography"],
    color: "#C447FF",
    sub_color: "#D06CFF",
    for: "fun",
    content: content
}