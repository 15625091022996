import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Hey from "./hey";

import Footer from "./footer";
import "./layout.css";
import projects from "../content/project";
import Project from "./Project";
import HeadPic from "./headpic";
import Jumbo from "./jumbo";

const Layout = ({ children }) => {
  const [wheelLevel, setWheelLevel] = useState(0);
  const [openProject, setOpenProject] = useState(false);
  const [project, setProject] = useState(projects[0].slug);

  const STATUS_MENU = "menu";
  const STATUS_TRANSITION = "transition";
  const STATUS_PROJECT = "project";
  const [status, setStatus] = useState(STATUS_MENU);
  const boxRef = useRef(null);

  const openProjectFn = (slug) => {
    setProject(slug);
    setOpenProject(true);
    boxRef.current.scrollTo(0, 0);
  };

  const projectsElements = projects.map((item, key) => {
    const animOut = (node, e, exit, entry) => {
      setStatus(STATUS_TRANSITION);
    };
    const animIn = (node, e, exit, entry) => {
      setStatus(STATUS_PROJECT);
    };
    return (
      <a
        to={"/" + item.slug} // AKA Link
        exit={{
          trigger: ({ node, e, exit, entry }) => animOut(node, e, exit, entry),
          length: 0.5,
          state: {
            mode: "in",
          },
        }}
        entry={{
          trigger: ({ node, e, exit, entry }) => animIn(node, e, exit, entry),
          length: 0.5,
          delay: 0.5,
        }}
      >
        <div
          className="project"
          id={key}
          onMouseEnter={(event) => setWheelLevel(key)}
          onClick={() => openProjectFn(item.slug)}
        >
          <h1 style={{ color: item.color }}>{item.name}</h1>
          <h1>{item.name}</h1>
          <div className="tag-box" style={{ borderColor: item.color }}>
            {item.tags.map((tag) => {
              return (
                <div
                  className="tag-item"
                  style={{ borderColor: item.color, color: item.color }}
                >
                  {tag}
                </div>
              );
            })}
          </div>
        </div>
      </a>
    );
  });

  const projectPictures = projects.map((item, key) => (
    <div className="postcard" key={key}>
      <div
        class="selected gatsby-image-wrapper  "
        style={{
          borderColor: item.sub_color,
          position: "relative",
          overflow: "hidden",
        }}
        className={
          key == wheelLevel
            ? "selected gatsby-image-wrapper"
            : " gatsby-image-wrapper"
        }
      >
        <div style={{ width: "100%", paddingBottom: "63%" }}></div>
        <picture>
          <img
            sizes="(max-width: 400px) 100vw, 400px"
            alt=""
            loading="lazy"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              opacity: "1",
              transition: "none 0s ease 0s",
            }}
            src={"/img/" + item.slug + "/cover.png"}
          />
        </picture>
      </div>
      {/* <img
        className={key == wheelLevel ? "selected" : ""}
        style={{ borderColor: item.color }}
        src={"/img/cover_" + item.slug + ".png"}
      /> */}
    </div>
  ));

  const gradientSteps = projects.map((item, key) => {
    const n = projects.length;
    const p = Math.round(key * (100 / n));
    return p + "% {background-color: " + item.color + ";}";
  });
  const gradient =
    `
    @keyframes gradient {
      ` +
    gradientSteps.join(" ") +
    `
    }
  `;
  const blockColor =
    `
    footer.open .block{
      background-color: ` +
    projects[0].color +
    `;
    }
  `;

  //useEffect(() => function() { }, []);
  //injectStyle(gradient); injectStyle(blockColor)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <Jumbo/>
      <div className="container">
        <div
          className={
            "wheel level-" +
            wheelLevel +
            "" +
            (status == STATUS_TRANSITION ? " deployed" : "")
          }
        >
          {projectPictures}
        </div>
        <div
          className={
            "store level-" +
            wheelLevel +
            "" +
            (status == STATUS_TRANSITION ? " deployed" : "")
          }
        >
          {projectsElements}
        </div>
        {/* TODO: write the good adress */}
        <Hey />
        <main className={status == STATUS_PROJECT ? "open" : ""}>
          {children}
        </main>
        <Project
          project={project}
          open={openProject}
          close={() => setOpenProject(false)}
          openProjectFn={openProjectFn}
          boxRef={boxRef}
        />
{/*         
        <Footer gradient={gradient} blockColor={blockColor} /> */}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
