import content from './family_photo.md'

export default {
    name: "Ernest's Booth",
    slug: "family_photo",
    description: "Ernest is a parisian food-help association. Before, working for them with this experiment, I’ve been involved as a volunteer. Ernest group has a unique energy, but like many others association it can be describe as an heterogeneous group of friends. A big family that would not fit in a picture so much its border are blurry. So, at the occasion of the major event we organised, I decided to take a veeery long picture.",
    tags: ["UX Design", "Processing", "Web Dev"],
    color: "#7FDD67",
    sub_color: "#99E485",
    with: "Steven Uch (Zerozoro) for the scenography design and construction",
    for: "Ernest (food help association)",
    youtube: "XSaz2tk92Pw",
    content: content
}

