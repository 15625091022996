import content from './hopsworks.md'

export default {
    name: "Hopsworks",
    slug: "hopsworks",
    description: "Hopsworks develops ML-apps, including a Feature Store. Working there has been quite a rewarding experience. When I arrived, we had an existing front-end application running but it was quite outdated. My mission was to design and rebuild from scratch a new front-end app while answering to new usage of Hopsworks.ai. I've been in charge of the whole front-end, including hiring and managing a front-end developer up to 5 members. This is the final result when I left the company end 2022.",
    tags: ["UX Design", "UI Design", "Product Management"],
    color: "#1CB182",
    sub_color: "#60C8A8",
    for: "Hopsworks",
    content: content
}